





































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  ITransactionData,
  ITransactionDataProcessed,
} from "@/store/modules/history/types";
import { AssetsDict, NftFamilyDict } from "@/store/modules/assets/types";
import { bnToBig } from "@/helpers/helper";
import { BN, Buffer } from "avalanche";

import StakingTx from "@/components/SidePanels/History/ViewTypes/StakingTx.vue";
import BaseTx from "@/components/SidePanels/History/ViewTypes/BaseTx.vue";
import ImportExport from "@/components/SidePanels/History/ViewTypes/ImportExport.vue";
import moment from "moment";
import { AvaNetwork } from "@/js/AvaNetwork";
import getMemoFromByteString from "@/services/history/utils";

@Component({
  components: {
    StakingTx,
    BaseTx,
    ImportExport,
  },
})
export default class TxRow extends Vue {
  @Prop() index!: number;
  @Prop() source!: ITransactionDataProcessed;

  get explorerUrl(): string | null {
    let network: AvaNetwork = this.$store.state.Network.selectedNetwork;
    if (network.explorerSiteUrl) {
      return `${network.explorerSiteUrl}/tx/${this.source.id}`;
    }
    return null;
  }

  get hasMultisig() {
    return (
      this.source.outputs?.filter((utxo) => utxo.addresses.length > 1).length >
        0 || false
    );
  }

  get date() {
    return new Date(this.source.timestamp);
  }
  get type() {
    return this.source.type;
  }

  get tx_comp() {
    switch (this.type) {
      case "export":
      case "import":
      case "pvm_export":
      case "pvm_import":
        return ImportExport;
      case "add_delegator":
      case "add_validator":
        return StakingTx;
      default:
        return BaseTx;
    }
  }

  get assets(): AssetsDict {
    return this.$store.state.Assets.assetsDict;
  }

  get nftFams(): NftFamilyDict {
    return this.$store.state.Assets.nftFamsDict;
  }

  get memo(): string | null {
    const memo = this.source.memo;
    return getMemoFromByteString(memo);
  }

  get mom() {
    return moment(this.source.timestamp);
  }
  get dayLabel() {
    return this.mom.format("dddd Do");
  }

  get monthLabel(): string {
    let month = this.mom.format("MMMM");
    return month;
  }

  get yearLabel(): string {
    return this.mom.format("Y");
  }
}
