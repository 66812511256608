
































import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Spinner from "@/components/misc/Spinner.vue";
import { UTXO } from "avalanche/dist/apis/platformvm";
import { ChainIdType } from "@/constants";
import { BN } from "avalanche";
import AvaAsset from "@/js/AvaAsset";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import { WalletType } from "@/js/wallets/types";

import { bnToBig } from "@/helpers/helper";
import { TxState } from "@/components/wallet/earn/ChainTransfer/types";

@Component({
  components: {
    Spinner,
  },
})
export default class TxStateCard extends Vue {
  @Prop() state!: TxState;
  @Prop() status!: string;
  @Prop() reason!: string;
  @Prop() txId!: string;
  @Prop({ default: true }) isExport?: boolean;
}
