
































import { Component, Vue } from "vue-property-decorator";
import { KeyPair } from "avalanche/dist/apis/avm";
import { ava, bintools } from "@/AVA";
import createHash from "create-hash";
import { getPreferredHRP } from "avalanche/dist/utils";
import { avm } from "@/AVA";
import { Buffer } from "avalanche";
import { digestMessage } from "@/helpers/helper";

@Component
export default class VerifyMessage extends Vue {
  message: string = "";
  addressX: string = "";
  addressP: string = "";
  signature = "";
  error = "";

  submit() {
    this.addressX = "";
    this.addressP = "";
    this.error = "";
    try {
      this.verify();
    } catch (e) {
      this.error = e;
    }
  }
  verify() {
    let digest = digestMessage(this.message);
    let digestBuff = Buffer.from(digest.toString("hex"), "hex");

    let networkId = ava.getNetworkID();

    let hrp = getPreferredHRP(networkId);
    let keypair = new KeyPair(hrp, "V");

    let signedBuff = bintools.cb58Decode(this.signature);

    let pubKey = keypair.recover(digestBuff, signedBuff);
    const addressBuff = KeyPair.addressFromPublicKey(pubKey);
    this.addressX = bintools.addressToString(hrp, "V", addressBuff);
    this.addressP = bintools.addressToString(hrp, "M", addressBuff);
  }

  clear() {
    this.message = "";
    this.signature = "";
    this.addressX = "";
    this.addressP = "";
    this.error = "";
  }

  deactivated() {
    this.clear();
  }

  get canSubmit() {
    if (!this.message || !this.signature) return false;

    return true;
  }
}
