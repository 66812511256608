


























import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import { BN } from "avalanche/dist";
import Big from "big.js";

@Component
export default class ConfirmPage extends Vue {
  @Prop() nodeID!: string;
  // @Prop() start!: Date
  @Prop() end!: Date;
  @Prop() amount!: BN;
  @Prop() rewardAddress!: string;
  @Prop() rewardDestination!: string;

  // get startDate(){
  //     return new Date(this.start);
  // }
  //
  // get endDate(){
  //     return new Date(this.end);
  // }

  get amtBig(): Big {
    let stakeAmt = Big(this.amount.toString()).div(Math.pow(10, 9));
    return stakeAmt;
  }

  get walletType() {
    if (this.rewardDestination === "local") {
      return "This wallet";
    }
    return "Custom";
  }

  get amtText(): string {
    let amt = this.amtBig;
    return amt.toLocaleString(9);
  }
}
