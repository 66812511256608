





























import { Vue, Component, Ref } from "vue-property-decorator";
import FileInput from "@/components/misc/FileInput.vue";
import { ImportKeyfileInput } from "@/store/types";
import { AllKeyFileTypes } from "@/js/IKeystore";
import { KEYSTORE_VERSION } from "@/js/Keystore";

@Component({
  components: {
    FileInput,
  },
})
export default class AddKeyFile extends Vue {
  canAdd: boolean = false;
  pass: string = "";
  keyfile: File | null = null;
  isLoading: boolean = false;
  err: string | null = null;
  fileText: string | null = null;

  @Ref("fileIn") readonly fileIn!: FileInput;

  get canSubmit() {
    return this.keyfile && this.pass && this.fileText ? true : false;
  }

  onfile(val: File) {
    this.keyfile = val;
    let parent = this;

    let reader = new FileReader();
    reader.addEventListener("load", async () => {
      let res = reader.result as string;
      parent.fileText = res;
    });
    reader.readAsText(val);
  }

  importKeyfile() {
    let fileData: AllKeyFileTypes;
    this.err = null;

    try {
      fileData = JSON.parse(this.fileText as string);
    } catch (e) {
      this.err = "Unable to parse JSON file.";
      return;
    }

    if (fileData.version != KEYSTORE_VERSION) {
      // TODO: update here?
      this.err =
        "Tried to import an old keystore version. Please update your keystore file before importing.";
      return;
    }

    this.isLoading = true;

    setTimeout(async () => {
      let input: ImportKeyfileInput = {
        password: this.pass,
        data: fileData,
      };

      try {
        await this.$store.dispatch("importKeyfile", input);
        // @ts-ignore
        this.$emit("success");
        this.clear();
      } catch (err) {
        this.isLoading = false;
        if (err === "INVALID_PASS") {
          this.err = "Invalid password.";
        } else {
          this.err = "Failed to read keystore file.";
        }

        // this.$store.dispatch("Notifications/add", {
        //     type: "error",
        //     title: "Import Failed",
        //     message: err.message
        // });
      }
    }, 200);
  }

  clear() {
    this.isLoading = false;
    this.pass = "";
    this.keyfile = null;
    this.canAdd = false;
    this.err = null;
    this.fileIn.clear();
  }
}
