

















































import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidatorListItem } from "@/store/modules/platform/types";
import { bnToBig } from "@/helpers/helper";
import { AvaNetwork } from "@/js/AvaNetwork";

@Component
export default class NodeCard extends Vue {
  @Prop() node!: ValidatorListItem;

  get uptimeText(): string {
    return (this.node.uptime * 100).toFixed(2) + "%";
  }

  get nodeStakeBig() {
    return bnToBig(this.node.validatorStake, 9);
  }

  get delegatedStakeBig() {
    return bnToBig(this.node.delegatedStake, 9);
  }

  get remainingStakeBig() {
    return bnToBig(this.node.remainingStake, 9);
  }

  get totalStakeBig() {
    return bnToBig(this.node.validatorStake.add(this.node.delegatedStake), 9);
  }

  get avascanURL() {
    let activeNet: AvaNetwork = this.$store.state.Network.selectedNetwork;

    if (activeNet.networkId === 1) {
      return `http://dijetsapi.centralus.cloudapp.azure.com`;
    } else {
      return `http://dijetsapi.centralus.cloudapp.azure.com`;
    }
  }

  get vscoutURL() {
    return `https://explorer.dijets.io`;
  }
}
