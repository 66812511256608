import { render, staticRenderFns } from "./VerifyMessage.vue?vue&type=template&id=7c00c9db&scoped=true&"
import script from "./VerifyMessage.vue?vue&type=script&lang=ts&"
export * from "./VerifyMessage.vue?vue&type=script&lang=ts&"
import style0 from "./VerifyMessage.vue?vue&type=style&index=0&id=7c00c9db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c00c9db",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
