





































































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import Spinner from "@/components/misc/Spinner.vue";
import { WalletType } from "@/js/wallets/types";
import { BN } from "avalanche";
import {
  djtxCtoX,
  ExportChainsC,
  ExportChainsP,
  ExportChainsX,
  GasHelper,
} from "@avalabs/avalanche-wallet-sdk";

@Component({
  components: { Spinner },
})
export default class ChainImport extends Vue {
  err = "";
  isSuccess = false;
  isLoading = false;
  txId = "";

  get wallet(): null | WalletType {
    let wallet: null | WalletType = this.$store.state.activeWallet;
    return wallet;
  }

  get isEVMSupported() {
    if (!this.wallet) return false;
    return this.wallet.ethAddress;
  }

  async atomicImportX(sourceChain: ExportChainsX) {
    this.beforeSubmit();
    if (!this.wallet) return;

    // // Import from C
    try {
      let txId = await this.wallet.importToXChain(sourceChain);
      this.onSuccess(txId);
    } catch (e) {
      if (this.isSuccess) return;
      this.onError(e);
    }
  }

  async atomicImportP(source: ExportChainsP) {
    this.beforeSubmit();
    if (!this.wallet) return;
    try {
      let txId = await this.wallet.importToPlatformChain(source);
      this.onSuccess(txId);
    } catch (e) {
      this.onError(e);
    }
  }

  async atomicImportC(source: ExportChainsC) {
    this.beforeSubmit();
    if (!this.wallet) return;
    try {
      const utxoSet = await this.wallet.evmGetAtomicUTXOs(source);
      const utxos = utxoSet.getAllUTXOs();

      const numIns = utxos.length;
      const baseFee = await GasHelper.getBaseFeeRecommended();

      if (numIns === 0) {
        throw new Error("Nothing to import.");
      }

      // Calculate number of signatures
      const numSigs = utxos.reduce((acc, utxo) => {
        return acc + utxo.getOutput().getAddresses().length;
      }, 0);

      const gas = GasHelper.estimateImportGasFeeFromMockTx(numIns, numSigs);

      const totFee = baseFee.mul(new BN(gas));
      let txId = await this.wallet.importToCChain(source, djtxCtoX(totFee));
      this.onSuccess(txId);
    } catch (e) {
      this.onError(e);
    }
  }

  deactivated() {
    this.err = "";
    this.txId = "";
    this.isSuccess = false;
  }

  beforeSubmit() {
    this.isLoading = true;
    this.err = "";
    this.isSuccess = false;
    this.txId = "";
  }

  onSuccess(txId: string) {
    this.isLoading = false;
    this.err = "";
    this.isSuccess = true;
    this.txId = txId;

    this.$store.dispatch("Notifications/add", {
      type: "success",
      title: "Import Success",
      message: txId,
    });

    setTimeout(() => {
      this.$store.dispatch("Assets/updateUTXOs");
      this.$store.dispatch("History/updateTransactionHistory");
    }, 3000);
  }

  onError(err: Error) {
    this.isLoading = false;
    let msg = "";
    if (err.message.includes("No atomic")) {
      this.err = "Nothing found to import.";
      return;
    } else {
      this.err = err.message;
    }
  }
}
