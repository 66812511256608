
























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import DjtxInput from "@/components/misc/DjtxInput.vue";
import { BN } from "avalanche";
import Big from "big.js";
import { bnToBig } from "@/helpers/helper";
import { ChainIdType } from "@/constants";
import { avm } from "@/AVA";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import AvaAsset from "@/js/AvaAsset";
import { ChainSwapFormData } from "@/components/wallet/earn/ChainTransfer/types";
import { AvaNetwork } from "@/js/AvaNetwork";

const chainTypes: ChainIdType[] = ["V", "M", "U"];
const chainNames = {
  V: "Value Chain",
  U: "Utility Chain",
  M: "Method Chain",
};

@Component({
  components: {
    DjtxInput,
  },
})
export default class Form extends Vue {
  sourceChain: ChainIdType = "V";
  targetChain: ChainIdType = "M";
  amt: BN = new BN(0);

  @Prop() balance!: Big;
  @Prop() maxAmt!: BN;
  @Prop() isConfirm!: boolean;

  clear() {
    this.amt = new BN(0);
    this.onChange();
  }

  get chainNames() {
    return chainNames;
  }

  get formAmtText() {
    return bnToBig(this.amt, 9).toLocaleString();
  }

  get sourceOptions(): ChainIdType[] {
    if (!this.isEVMSupported) {
      return ["V", "M"];
    }

    let all = [...chainTypes];
    return all;
  }

  get destinationOptions(): ChainIdType[] {
    return {
      V: ["M", "U"],
      M: ["V", "U"],
      U: ["V", "M"],
    }[this.sourceChain] as ChainIdType[];
  }

  @Watch("destinationOptions")
  onDestinationsChange() {
    this.targetChain = this.destinationOptions[0];
    this.onChange();
  }

  get wallet() {
    let wallet: MnemonicWallet = this.$store.state.activeWallet;
    return wallet;
  }

  get isEVMSupported() {
    return this.wallet.ethAddress;
  }

  onChangeSource(ev: any) {
    let val: ChainIdType = ev.target.value;
    this.sourceChain = val;
    this.onChange();
  }

  onChangeDestination(ev: any) {
    let val: ChainIdType = ev.target.value;
    this.targetChain = val;
    this.onChange();
  }

  onAmtChange() {
    this.onChange();
  }

  onChange() {
    let data: ChainSwapFormData = {
      sourceChain: this.sourceChain,
      destinationChain: this.targetChain,
      amount: this.amt,
    };
    this.$emit("change", data);
  }

  mounted() {
    this.onChange();
  }
}
