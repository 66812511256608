






























import "reflect-metadata";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BN } from "avalanche";
import Big from "big.js";

@Component
export default class ConfirmPage extends Vue {
  @Prop() nodeID!: string;
  @Prop() end!: Date;
  @Prop() delegationFee!: number;
  @Prop() amount!: BN;
  @Prop() rewardAddress!: string;
  @Prop() rewardDestination!: string;

  // amountCopy: BN = new BN(0);

  // @Watch('amount')
  // onAmountChange(val: BN){
  //     console.log(val.toString(), val);
  //     this.amountCopy = val.clone()
  //     this.amountCopy = val.
  // }

  // get startDate(){
  //     return new Date(this.start);
  // }
  //
  // get endDate(){
  //     return new Date(this.end);
  // }

  get amtBig(): Big {
    let stakeAmt = Big(this.amount.toString()).div(Math.pow(10, 9));
    return stakeAmt;
  }

  get walletType() {
    if (this.rewardDestination === "local") {
      return this.$t("earn.validate.confirmation.type_local");
    }
    return this.$t("earn.validate.confirmation.type_custom");
  }

  get amtText(): string {
    let amt = this.amtBig;
    return amt.toLocaleString(9);
  }
}
