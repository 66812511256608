








































































































































































































































import "reflect-metadata";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
//@ts-ignore
import DjtxInput from "@/components/misc/DjtxInput.vue";
import { BN } from "avalanche";
import Big from "big.js";
//@ts-ignore
import { QrInput } from "@avalabs/vue_components";
import { bintools, pChain } from "@/AVA";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import ConfirmPage from "@/components/wallet/earn/Validate/ConfirmPage.vue";
import moment from "moment";
import { bnToBig, calculateStakingReward } from "@/helpers/helper";
import { ONEDJTX } from "avalanche/dist/utils";
import Tooltip from "@/components/misc/Tooltip.vue";
import CurrencySelect from "@/components/misc/CurrencySelect/CurrencySelect.vue";
import Spinner from "@/components/misc/Spinner.vue";
import DateForm from "@/components/wallet/earn/DateForm.vue";
import UtxoSelectForm from "@/components/wallet/earn/UtxoSelectForm.vue";
import Expandable from "@/components/misc/Expandable.vue";
import { AmountOutput, UTXO } from "avalanche/dist/apis/platformvm";
import { WalletType } from "@/js/wallets/types";

const MIN_MS = 60000;
const HOUR_MS = MIN_MS * 60;
const DAY_MS = HOUR_MS * 24;

const MIN_STAKE_DURATION = DAY_MS * 14;
const MAX_STAKE_DURATION = DAY_MS * 365;

@Component({
  name: "add_validator",
  components: {
    Tooltip,
    DjtxInput,
    QrInput,
    ConfirmPage,
    CurrencySelect,
    Spinner,
    DateForm,
    Expandable,
    UtxoSelectForm,
  },
})
export default class AddValidator extends Vue {
  startDate: string = new Date(Date.now() + MIN_MS * 15).toISOString();
  endDate: string = new Date().toISOString();
  delegationFee: string = "2.0";
  nodeId = "";
  rewardIn: string = "";
  rewardDestination = "local"; // local || custom
  isLoading = false;
  isConfirm = false;
  err: string = "";
  stakeAmt: BN = new BN(0);

  minFee = 2;

  formNodeId = "";
  formAmt: BN = new BN(0);
  formEnd: Date = new Date();
  formFee: number = 0;
  formRewardAddr = "";
  formUtxos: UTXO[] = [];

  txId = "";
  txStatus: string | null = null;
  txReason: null | string = null;

  isSuccess = false;

  currency_type = "DJT";

  mounted() {
    this.rewardSelect("local");
  }

  onFeeChange() {
    let num = parseFloat(this.delegationFee);
    if (num < this.minFee) {
      this.delegationFee = this.minFee.toString();
    } else if (num > 100) {
      this.delegationFee = "100";
    }
  }

  setEnd(val: string) {
    this.endDate = val;
  }

  get rewardAddressLocal() {
    let wallet: MnemonicWallet = this.$store.state.activeWallet;
    return wallet.getPlatformRewardAddress();
  }

  rewardSelect(val: "local" | "custom") {
    if (val === "local") {
      this.rewardIn = this.rewardAddressLocal;
    } else {
      this.rewardIn = "";
    }
    this.rewardDestination = val;
  }

  // Returns true to show a warning about short validation periods that can not take any delegators
  get warnShortDuration(): boolean {
    let dur = this.stakeDuration;

    // If duration is less than 16 days give a warning
    if (dur <= DAY_MS * 16) {
      return true;
    }
    return false;
  }

  get stakeDuration(): number {
    let start = new Date(this.startDate);
    let end = new Date(this.endDate);

    if (this.isConfirm) {
      end = this.formEnd;
    }

    let diff = end.getTime() - start.getTime();
    return diff;
  }

  get durationText() {
    let d = moment.duration(this.stakeDuration, "milliseconds");
    let days = Math.floor(d.asDays());
    return `${days} days ${d.hours()} hours ${d.minutes()} minutes`;
  }

  get denomination() {
    return 9;
  }

  get platformUnlocked(): BN {
    return this.$store.getters["Assets/walletPlatformBalance"].available;
  }

  get platformLockedStakeable(): BN {
    return this.$store.getters["Assets/walletPlatformBalanceLockedStakeable"];
  }

  get feeAmt(): BN {
    return pChain.getTxFee();
  }

  get utxosBalance(): BN {
    return this.formUtxos.reduce((acc, val: UTXO) => {
      let out = val.getOutput() as AmountOutput;
      return acc.add(out.getAmount());
    }, new BN(0));
  }

  get maxAmt(): BN {
    // let pAmt = this.platformUnlocked.add(this.platformLockedStakeable)
    let pAmt = this.utxosBalance;
    // let fee = this.feeAmt;

    // absolute max stake
    let mult = new BN(10).pow(new BN(6 + 9));
    let absMaxStake = new BN(3).mul(mult);

    // If above stake limit
    if (pAmt.gt(absMaxStake)) {
      return absMaxStake;
    }

    // let res = pAmt.sub(fee);
    const ZERO = new BN("0");
    if (pAmt.gt(ZERO)) {
      return pAmt;
    } else {
      return ZERO;
    }
  }

  get maxDelegationAmt(): BN {
    let stakeAmt = this.stakeAmt;

    let maxRelative = stakeAmt.mul(new BN(5));

    // absolute max stake
    let mult = new BN(10).pow(new BN(6 + 9));
    let absMaxStake = new BN(3).mul(mult);

    let res;
    if (maxRelative.lt(absMaxStake)) {
      res = maxRelative.sub(stakeAmt);
    } else {
      res = absMaxStake.sub(stakeAmt);
    }

    return BN.max(res, new BN(0));
  }

  get maxDelegationText() {
    return bnToBig(this.maxDelegationAmt, 9).toLocaleString(9);
  }

  get maxDelegationUsdText() {
    let big = bnToBig(this.maxDelegationAmt, 9);
    let res = big.times(this.djtxPrice);
    return res.toLocaleString(2);
  }

  get djtxPrice(): Big {
    return Big(this.$store.state.prices.usd);
  }

  get estimatedReward(): Big {
    let start = new Date(this.startDate);
    let end = new Date(this.endDate);
    let duration = end.getTime() - start.getTime(); // in ms

    let currentSupply = this.$store.state.Platform.currentSupply;
    let estimation = calculateStakingReward(
      this.stakeAmt,
      duration / 1000,
      currentSupply
    );
    let res = bnToBig(estimation, 9);

    return res;
  }

  get estimatedRewardUSD() {
    return this.estimatedReward.times(this.djtxPrice);
  }

  updateFormData() {
    this.formNodeId = this.nodeId.trim();
    this.formAmt = this.stakeAmt;
    this.formEnd = new Date(this.endDate);
    this.formRewardAddr = this.rewardIn;
    this.formFee = parseFloat(this.delegationFee);
  }

  confirm() {
    if (!this.formCheck()) return;
    this.updateFormData();
    this.isConfirm = true;
  }
  cancelConfirm() {
    this.isConfirm = false;
  }

  cancel() {
    this.$emit("cancel");
  }

  get canSubmit() {
    if (!this.nodeId) {
      return false;
    }

    if (this.stakeAmt.isZero()) {
      return false;
    }

    if (!this.rewardIn) {
      return false;
    }

    return true;
  }

  formCheck(): boolean {
    this.err = "";

    // Reward Address
    if (this.rewardDestination !== "local") {
      let rewardAddr = this.rewardIn;

      // If it doesnt start with P
      if (rewardAddr[0] !== "M") {
        this.err = this.$t("earn.validate.errs.address") as string;
        return false;
      }

      // not a valid address
      try {
        bintools.stringToAddress(rewardAddr);
      } catch (e) {
        this.err = this.$t("earn.validate.errs.address") as string;
        return false;
      }
    }

    // Not a valid Node ID
    if (!this.nodeId.includes("NodeID-")) {
      this.err = this.$t("earn.validate.errs.id") as string;
      return false;
    }

    // Delegation Fee
    if (parseFloat(this.delegationFee) < this.minFee) {
      this.err = this.$t("earn.validate.errs.fee", [this.minFee]) as string;
      return false;
    }

    // Stake amount
    if (this.stakeAmt.lt(this.minStakeAmt)) {
      let big = Big(this.minStakeAmt.toString()).div(Math.pow(10, 9));
      this.err = this.$t("earn.validate.errs.amount", [
        big.toLocaleString(),
      ]) as string;
      return false;
    }

    return true;
  }

  async submit() {
    if (!this.formCheck()) return;
    let wallet: WalletType = this.$store.state.activeWallet;

    // Start delegation in 5 minutes
    let startDate = new Date(Date.now() + 5 * MIN_MS);
    let endMs = this.formEnd.getTime();
    let startMs = startDate.getTime();

    // If End date - start date is greater than max stake duration, adjust start date
    if (endMs - startMs > MAX_STAKE_DURATION) {
      startDate = new Date(endMs - MAX_STAKE_DURATION);
    }

    try {
      this.isLoading = true;
      this.err = "";
      let txId = await wallet.validate(
        this.formNodeId,
        this.formAmt,
        startDate,
        this.formEnd,
        this.formFee,
        this.formRewardAddr,
        this.formUtxos
      );
      this.isLoading = false;
      this.onTxSubmit(txId);
    } catch (err) {
      this.isLoading = false;
      this.onerror(err);
    }
  }

  onTxSubmit(txId: string) {
    this.txId = txId;
    this.isSuccess = true;
    this.updateTxStatus(txId);
  }

  onsuccess() {
    this.$store.dispatch("Notifications/add", {
      type: "success",
      title: "Validator Added",
      message: "Your tokens are now locked to stake.",
    });

    // Update History
    setTimeout(() => {
      this.$store.dispatch("Assets/updateUTXOs");
      this.$store.dispatch("History/updateTransactionHistory");
    }, 3000);
  }

  async updateTxStatus(txId: string) {
    let res = await pChain.getTxStatus(txId);

    let status;
    let reason = null;
    if (typeof res === "string") {
      status = res;
    } else {
      status = res.status;
      reason = res.reason;
    }

    if (!status || status === "Processing" || status === "Unknown") {
      setTimeout(() => {
        this.updateTxStatus(txId);
      }, 5000);
    } else {
      this.txStatus = status;
      this.txReason = reason;

      if (status === "Committed") {
        this.onsuccess();
      }
    }
  }

  get minStakeAmt(): BN {
    return this.$store.state.Platform.minStake;
  }

  onerror(err: any) {
    let msg: string = err.message;
    console.error(err);

    if (msg.includes("startTime")) {
      this.err = this.$t("earn.validate.errs.date") as string;
    } else if (msg.includes("must be at least")) {
      let minAmt = this.minStakeAmt;
      let big = Big(minAmt.toString()).div(Math.pow(10, 9));
      this.err = this.$t("earn.validate.errs.amount", [
        big.toLocaleString(),
      ]) as string;
    } else if (msg.includes("nodeID")) {
      this.err = this.$t("earn.validate.errs.id") as string;
    } else if (msg.includes("address format")) {
      this.err = this.$t("earn.validate.errs.address") as string;
    } else {
      this.err = err.message;
    }

    this.$store.dispatch("Notifications/add", {
      type: "error",
      title: "Validation Failed",
      message: "Failed to add validator.",
    });
  }
}
