





















import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import { UTXO } from "avalanche/dist/apis/platformvm";
import { ChainIdType } from "@/constants";
import { BN } from "avalanche";
import AvaAsset from "@/js/AvaAsset";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import { WalletType } from "@/js/wallets/types";

import { bnToBig } from "@/helpers/helper";
import NumberCounter from "@/components/misc/NumberCounter.vue";

const chainTypes: ChainIdType[] = ["V", "M", "U"];
const chainNames = {
  V: "Value Chain",
  U: "Utility Chain",
  M: "Method Chain",
};

@Component({
  components: {
    NumberCounter,
  },
})
export default class ChainCard extends Vue {
  // @Model('change', { type: String }) readonly chain!: ChainIdType
  @Prop() chain!: ChainIdType;
  // @Prop() exclude!: ChainIdType
  @Prop({ default: true }) isSource?: boolean;

  onChange(ev: any) {
    let val: ChainIdType = ev.target.value;
    this.$emit("change", val);
  }

  get chainNames() {
    return chainNames;
  }

  get ava_asset(): AvaAsset | null {
    let ava = this.$store.getters["Assets/AssetAVA"];
    return ava;
  }

  get wallet(): WalletType {
    let wallet: MnemonicWallet = this.$store.state.activeWallet;
    return wallet;
  }

  get platformUnlocked(): BN {
    return this.$store.getters["Assets/walletPlatformBalance"].available;
  }

  get avmUnlocked(): BN {
    if (!this.ava_asset) return new BN(0);
    return this.ava_asset.amount;
  }

  get evmUnlocked(): BN {
    let balRaw = this.wallet.ethBalance;
    return balRaw.div(new BN(Math.pow(10, 9)));
  }

  get balance() {
    if (this.chain === "V") {
      return this.avmUnlocked;
    } else if (this.chain === "M") {
      return this.platformUnlocked;
    } else {
      return this.evmUnlocked;
    }
  }

  get balanceBig() {
    return bnToBig(this.balance, 9);
  }
  get balanceText() {
    return this.balanceBig.toLocaleString();
  }

  mounted() {}
}
